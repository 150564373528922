import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription, throwError } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { UserModel } from '../_models/user.model';
import { AuthModel } from '../_models/auth.model';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { SocialUser } from 'angularx-social-login';

@Injectable({
  providedIn: 'root',
})

export class AuthService implements OnDestroy {

  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;
  isLoadingSubject: BehaviorSubject<boolean>;


  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // public methods
  login(socialUser: SocialUser): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(socialUser).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        localStorage.setItem('user', JSON.stringify(socialUser));

        return result;
      }),
      switchMap(() => {
        return this.getUserById();
      }),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );

  }


  // public methods
  recoveryPass(lg: any): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.recoveryPass(lg).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        return result;
      }),
      switchMap(() => {
        return this.getUserById();
      }),
      catchError((err) => {
        console.error('err', err);
        return of(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );

  }
  // public methods
  checkCode(lg: any): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.checkCode(lg).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        return result;
      }),
      switchMap(() => {
        return this.getUserById();
      }),
      catchError((err) => {
        console.error('err', err);
        return throwError(() => err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );

  }
  // public methods
  fakeLogin(lg: any): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.fakeLogin(lg).pipe(
      map((auth: AuthModel) => {
        const response = this.setFakeAuthFromLocalStorage(
          this.getAuthFromLocalStorage()
        );
        const result = this.setAuthFromSessionStorage(auth);
        return result;
      }),
      switchMap(() => {
        return this.getUserById();
      }),
      catchError((err) => {
        console.error('err', err);
        return of(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );

  }
  // public methods
  loginLocal(lg: any): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.loginLocal(lg).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        return result;
      }),
      switchMap(() => {
        return this.getUserById();
      }),
      catchError((err) => {
        console.error('err', err);
        return of(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );

  }

  logout() {
    sessionStorage.clear();
    localStorage.removeItem(this.authLocalStorageToken);
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<UserModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.token) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.token).pipe(
      map((user: UserModel) => {
        if (user) {
          // this.getUserById();
          this.currentUserSubject = new BehaviorSubject<UserModel>(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  webviewStaff(): any | boolean {
    try {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);

      // Agora você pode acessar os parâmetros de consulta
      const staffToken = params.get('token'); // Retor
      const idParam = params.get('id'); // Retor
      const collaborator = params.get('collaborator'); // Retor
      if (staffToken) {
        return { token: staffToken, id: idParam, collaborator };
      }
    } catch (error) {

      return false
    }
  }

  getUserById(): Observable<UserModel> {

    const auth = this.getAuthFromLocalStorage();
    if ((!auth || !auth.token) && !this.webviewStaff()) {
      return of(undefined);
    } else if (this.webviewStaff()) {
      auth.id = this.webviewStaff()?.id
      auth.token = `${this.webviewStaff()?.token}`
      this.isLoadingSubject.next(false)

    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserLogged(auth).pipe(
      map((data: any) => {
        if (this.webviewStaff()) {
          return auth
        }
        if (data.success && data.data.Uid) {
          this.currentUserSubject = new BehaviorSubject<UserModel>(data.data);
        } else {
          this.logout();
        }
        return data.data;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  registration(user: UserModel): Observable<any> {
    // this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user);
    //   map(() => {
    //     this.isLoadingSubject.next(false);
    //   }),
    //   switchMap(() => this.login(user.email, user.password)),
    //   catchError((err) => {
    //     console.error('err', err);
    //     return of(undefined);
    //   }),
    //   finalize(() => this.isLoadingSubject.next(false))
    // );
  }

  checkCustomerImporter(cpf: string, birthdate: string): Observable<any> {
    return this.authHttpService.checkCustomer({
      cpf, birthdate
    })
  }
  signup(user: UserModel): Observable<any> {
    // this.isLoadingSubject.next(true);
    return this.authHttpService.createUserPassword(user);
    //   map(() => {
    //     this.isLoadingSubject.next(false);
    //   }),
    //   switchMap(() => this.login(user.email, user.password)),
    //   catchError((err) => {
    //     console.error('err', err);
    //     return of(undefined);
    //   }),
    //   finalize(() => this.isLoadingSubject.next(false))
    // );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setFakeAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.token) {
      localStorage.setItem('admin-view', JSON.stringify(auth));
      return true;
    }
    return false;
  }
  setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.token) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }
  setAuthFromSessionStorage(auth: AuthModel): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.token) {
      sessionStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }
  checkIsAdmin() {
    if (this.webviewStaff()) {
      return true
    }
    const authData = JSON.parse(
      localStorage.getItem(this.authLocalStorageToken)
    );
    return authData?.permission === 99
  }
  public getFakeLogin(): AuthModel {
    try {
      const authData = JSON.parse(
        localStorage.getItem('admin-view')
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
  public getAuthFromLocalStorage(): AuthModel {
    try {
      if (this.getFakeLogin() && !sessionStorage.getItem(this.authLocalStorageToken)) {
        localStorage.removeItem("admin-view");
      }
      let storage = this.getFakeLogin() && sessionStorage.getItem(this.authLocalStorageToken) ? sessionStorage.getItem(this.authLocalStorageToken) : localStorage.getItem(this.authLocalStorageToken)

      if (this.webviewStaff()) {
        storage = JSON.stringify({
          token: this.webviewStaff()?.token,
          id: this.webviewStaff()?.id,
          permission: 99
        })
      }
      const authData = JSON.parse(
        storage
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
  public getUserFromLocalStorage(): UserModel {
    try {
      if (this.getFakeLogin() && !sessionStorage.getItem(this.authLocalStorageToken)) {
        localStorage.removeItem("admin-view");
      }
      let storage = this.getFakeLogin() && sessionStorage.getItem(this.authLocalStorageToken) ? sessionStorage.getItem(this.authLocalStorageToken) : localStorage.getItem(this.authLocalStorageToken)

      if (this.webviewStaff()) {
        storage = JSON.stringify({
          token: this.webviewStaff()?.token,
          id: this.webviewStaff()?.id,
          permission: 99
        })
      }
      const authData = JSON.parse(
        storage
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public getUserDataFromSessionStorage(): UserModel {
      return JSON.parse(window.sessionStorage.getItem('userData'));
  }

  public hasPermission(perm: number): boolean {
    if (this.getAuthFromLocalStorage().permission == perm) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}


