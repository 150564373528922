import { CivilStateModel } from 'src/app/pages/people/models/civil-state.model';
import { AddressModel } from './address.model';
import { AuthModel } from './auth.model';
import { DocumentsModel } from './documents.model';
import { SocialNetworksModel } from './social-networks.model';

export class UserModel extends AuthModel {
  socialname: string;
  fullname: string;
  email: string;
  pic: string;
  cpf: string;
  Uid: string;
  hasCPF?: boolean;
  sexo: string;
  rg: string;
  rgExpedition: string;
  birthdate: string;
  lastname?: string;
  nacionality: string;
  occupation: string;
  companyName: string;
  iduser: number;
  level: number;
  IdZSystem: number;
  IDTransaction?: number;
  IDTransactionVital?: number;
  paidStaff: string;
  phone: string;
  fatherName: string;
  motherName: string;
  address?: AddressModel;
  documents?: DocumentsModel;
  civilState?: CivilStateModel;
  socialNetworks?: SocialNetworksModel;
  successDateVital?: string;
  successDate?: string;
  FinishStepOne?: boolean;
  requestUpdate?: boolean;
  stepFour?: RequestUpdateModel[];
  lastLogin?: string;
  createdAt?: string;
  Annotations?: string;
  Abroad?: boolean;
  IsForeigner?: boolean = false
  // personal information
  firstname: string;
  username?: string
  website: string;
  acceptedTerm?: boolean;
  acceptedTermVital?: boolean;
  Reports?: string;
  authorizeDownload?: boolean;
  authorizeDownloadVital?: boolean;
  envType?: string;
  // account information
  password: string;
  language: string;
  status?: string;
  timeZone: string;
  communication: {
    email: boolean;
    sms: boolean;
    phone: boolean;
  };
  company?: string;
  // email settings
  emailSettings: {
    emailNotification: boolean;
    sendCopyToPersonalEmail: boolean;
    activityRelatesEmail: {
      youHaveNewNotifications: boolean;
      youAreSentADirectMessage: boolean;
      someoneAddsYouAsAsAConnection: boolean;
      uponNewOrder: boolean;
      newMembershipApproval: boolean;
      memberRegistration: boolean;
    };
    updatesFromKeenthemes: {
      newsAboutKeenthemesProductsAndFeatureUpdates: boolean;
      tipsOnGettingMoreOutOfKeen: boolean;
      thingsYouMissedSindeYouLastLoggedIntoKeen: boolean;
      newsAboutMetronicOnPartnerProductsAndOtherServices: boolean;
      tipsOnMetronicBusinessProducts: boolean;
    };
  };
  products: string[];

  setUser(user: any) {
    this.Uid = user.Uid;
    this.id = user.id;
    this.level = user.level;
    this.socialname = user.socialname || "";
    this.fullname = user.fullname || "";
    this.firstname = user.firstname || "";
    this.lastname = user.lastname || "";
    this.email = user.email || "";
    this.sexo = user.gender || "";
    this.pic = user.pic || "./assets/media/users/default.jpg";
    this.cpf = user.cpf || "";
    this.hasCPF = user.hasCPF || false;
    this.rg = user.rg || "";
    this.birthdate = user.birthdate || "";
    this.IdZSystem = user.IdZSystem;
    this.IDTransaction = user.IDTransaction;
    this.IDTransactionVital = user.IDTransactionVital;
    this.paidStaff = user.paidStaff || '';
    this.phone = user.phone || "";
    this.motherName = user.motherName || "";
    this.fatherName = user.phfatherNameone || "";
    this.address = user.address || new AddressModel();
    this.documents = user.documents || new DocumentsModel();
    this.socialNetworks = user.socialNetworks;
    this.acceptedTerm = user.acceptedTerm;
    this.password = user.password || "";
    this.successDateVital = user.successDateVital || "";
    this.successDateVital = user.successDateVital || "";
    this.FinishStepOne = user.FinishStepOne;
    this.requestUpdate = !!user.requestUpdate?.find(el => el.active && el.productType === user.envType).active;
    this.Annotations = user.Annotations || "";
    this.status = user.status || "";
    this.envType = user.envType;
    this.IsForeigner = user.IsForeigner;
    this.company = user.company;
    this.products = user.products;
  }
}

export const TESTAMENT_TYPE: {
  VITAL: string,
  PARTICULAR: string,
  INVENTORY: string,
} = {
  VITAL: 'VITAL',
  PARTICULAR: 'PARTICULAR',
  INVENTORY: 'INVENTARIO'
}


export type RequestUpdateModel = {
  productType: string,
  createdAt: string,
  active: string
}

export const discriminatorEnv = () => {
  window.location.href = '/home'
}