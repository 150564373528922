<ng-template #content let-modal>
  <div class="modal-body-terms" id="top">
    <div id="top-doc" class="content-term">
      <!-- <p class="c14"><span class="c8 c11 c10">TERMOS DA Planeje bem</span></p>
        <p class="c4"><span class="c8 c10 c11"></span></p>
        <p class="c4"><span class="c8 c11 c10"></span></p>
        <p class="c4"><span class="c8 c11 c10"></span></p> -->
      <h4 class="c11" id="h.lrmdog3rw6tr">
        <span class="c2 c7"
          >Termos, Condi&ccedil;&otilde;es de Uso e Pol&iacute;tica de
          Privacidade</span
        >
      </h4>
      <h5 class="c1 c12" id="h.xe1gx1kqjdiv">
        <span class="c0"></span>
      </h5>
      <h5 class="c1" id="h.ew4actq8suqq">
        <span class="c2"
          >Estes Termos e Condi&ccedil;&otilde;es de Uso (&ldquo;Termos&rdquo;)
          regem a utiliza&ccedil;&atilde;o dos servi&ccedil;os oferecidos pela
          Planeje Bem &nbsp;Ltda., sociedade empres&aacute;ria limitada,
          inscrita no CNPJ/MF sob o n&ordm; 36.939.472/0001-51 (&ldquo;Planeje
          Bem&rdquo; ou &ldquo;Empresa&rdquo;) por meio do website
        </span>
        <span class="c2 c13">
          <a
            class="c6"
            href="https://www.google.com/url?q=http://www.planejebem.app&amp;sa=D&amp;source=editors&amp;ust=1728591531440591&amp;usg=AOvVaw3WPWwjDOFzMshG-36KfWJI"
            >www.planejebem.app</a
          >
        </span>
        <span class="c0"
          >&nbsp;, aplicativo espec&iacute;fico (app), ou outras plataformas de
          comunica&ccedil;&atilde;o com usu&aacute;rios, tais como WhatsApp,
          Facebook Messenger e outros (&ldquo;Servi&ccedil;os&rdquo; e
          &ldquo;Plataforma&rdquo;, respectivamente). O usu&aacute;rio de
          qualquer Servi&ccedil;o (&ldquo;Usu&aacute;rio&rdquo;) deve concordar
          com estes Termos previamente &agrave; sua
          utiliza&ccedil;&atilde;o.</span
        >
      </h5>
      <h5 class="c1" id="h.pb4k4hmtszph">
        <span class="c2"
          >Ao utilizar quaisquer Servi&ccedil;os fornecidos pela
        </span>
        <span class="c2">Planeje Bem</span>
        <span class="c0"
          >, o Usu&aacute;rio declara que leu, compreendeu e concordou com estes
          Termos, inclusive com a pol&iacute;tica de privacidade da &lsquo;l
          descrita nestes Termos (&ldquo;Pol&iacute;tica de
          Privacidade&rdquo;).</span
        >
      </h5>
      <h5 class="c1" id="h.qsomkyvqccmh">
        <span class="c2"
          >A Empresa recomenda que o Usu&aacute;rio leia com cuidado a
          integralidade deste e outros documentos aplic&aacute;veis aos
          Servi&ccedil;os disponibilizados pela
        </span>
        <span class="c2">Planeje Bem</span>
        <span class="c0"
          >&nbsp;antes de utilizar os Servi&ccedil;os. Caso n&atilde;o concorde
          com alguma cl&aacute;usula prevista em qualquer documento, o
          Usu&aacute;rio n&atilde;o est&aacute; autorizado e n&atilde;o
          dever&aacute; utilizar os Servi&ccedil;os da Empresa. Estes Termos
          estar&atilde;o dispon&iacute;veis online para leitura a qualquer
          momento.</span
        >
      </h5>
      <h5 class="c1" id="h.aa0v72bk6hli">
        <span class="c0"
          >Com o intuito de garantir o constante aperfei&ccedil;oamento destes
          Termos e cumprimento da legisla&ccedil;&atilde;o e
          regulamenta&ccedil;&atilde;o aplic&aacute;veis, a Empresa se reserva o
          direito, a seu exclusivo crit&eacute;rio e a qualquer tempo, de
          modificar estes Termos e suas pol&iacute;ticas adjacentes, sem
          pr&eacute;via comunica&ccedil;&atilde;o, exceto em caso de
          veda&ccedil;&atilde;o legal nesse sentido. A continuidade no uso dos
          Servi&ccedil;os ap&oacute;s tais altera&ccedil;&otilde;es
          constituir&aacute; o consentimento do Usu&aacute;rio em
          rela&ccedil;&atilde;o a todas as mudan&ccedil;as realizadas. Caso o
          Usu&aacute;rio n&atilde;o concorde com qualquer das
          altera&ccedil;&otilde;es, o Usu&aacute;rio dever&aacute; se abster de
          utilizar os Servi&ccedil;os imediatamente, sem preju&iacute;zo do
          cumprimento das obriga&ccedil;&otilde;es decorrentes dos
          Servi&ccedil;os contratados anteriormente que estejam em processo de
          execu&ccedil;&atilde;o.</span
        >
      </h5>
      <h5 class="c1" id="h.xcuaugynlz5f">
        <span class="c5 c2">1. Condi&ccedil;&otilde;es de Acesso e Uso</span>
      </h5>
      <h5 class="c1" id="h.7bsqt8e3qga">
        <span class="c0"
          >1.1. Ao utilizar os Servi&ccedil;os, o Usu&aacute;rio declara
          expressamente que realizou a leitura completa destes Termos e
          manifesta sua concord&acirc;ncia com rela&ccedil;&atilde;o &agrave;s
          suas disposi&ccedil;&otilde;es. Por meio de tal ato, o Usu&aacute;rio
          manifesta sua aceita&ccedil;&atilde;o livre e expressa, sem quaisquer
          reservas, acerca de todas as cl&aacute;usulas destes Termos.</span
        >
      </h5>
      <h5 class="c1" id="h.4lg4uu382w5u">
        <span class="c0"
          >1.2. Ao fazer o uso dos Servi&ccedil;os, o Usu&aacute;rio se
          compromete e se responsabiliza a fornecer, no momento do seu cadastro
          ou quando solicitado, dados e informa&ccedil;&otilde;es
          ver&iacute;dicas e completas. O Usu&aacute;rio garante a autenticidade
          de todos os dados informados, responsabilizando-se em &acirc;mbito
          civil e penal por quaisquer danos e/ou preju&iacute;zos eventualmente
          causados &agrave; Empresa ou a terceiros decorrentes do fornecimento
          de informa&ccedil;&otilde;es inexatas, incompletas ou
          inver&iacute;dicas.</span
        >
      </h5>
      <h5 class="c1" id="h.ilxcctzbkoqk">
        <span class="c0"
          >1.3. Ao usar os Servi&ccedil;os, o Usu&aacute;rio tem ci&ecirc;ncia
          de que as opera&ccedil;&otilde;es comerciais realizadas na Plataforma
          poder&atilde;o ser objeto de valida&ccedil;&atilde;o automatizada e
          manual, bem como as informa&ccedil;&otilde;es poder&atilde;o ser
          disponibilizadas para empresa especializada neste tipo de
          valida&ccedil;&atilde;o.</span
        >
      </h5>
      <h5 class="c1" id="h.hubyigs1bwpq">
        <span class="c0"
          >1.4. O Usu&aacute;rio se compromete a manter todas as suas
          informa&ccedil;&otilde;es cadastrais permanentemente
          atualizadas.</span
        >
      </h5>
      <h5 class="c1" id="h.e5zdm1o7poot">
        <span class="c0"
          >1.5. O Usu&aacute;rio est&aacute; ciente que os documentos gerados
          pela Plataforma s&atilde;o automatizados. Portanto, &eacute; de sua
          inteira responsabilidade a inser&ccedil;&atilde;o de
          informa&ccedil;&otilde;es adequadas, corretas, completas e sem
          potencial ofensivo a terceiros. Sendo vedado a inser&ccedil;&atilde;o
          de conte&uacute;do sigiloso como senhas ou informa&ccedil;&otilde;es
          que coloquem em risco a pr&oacute;pria seguran&ccedil;a ou de
          terceiros, uma vez que os documentos ser&atilde;o em algum momento
          publicizados e precisar&atilde;o de assinaturas de outras
          pessoas.</span
        >
      </h5>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c0"
          >1.6. A Planeje Bem n&atilde;o presta servi&ccedil;os de assessoria ou
          consultoria jur&iacute;dica, cont&aacute;bil, financeira,
          regulat&oacute;ria ou fiscal de qualquer outra natureza. Esta Empresa
          se limita a demonstrar estimativas baseadas nas
          informa&ccedil;&otilde;es inseridas pelo Usu&aacute;rio ao longo da
          Plataforma e na legisla&ccedil;&atilde;o vigente, a qual est&aacute;
          sujeita a muta&ccedil;&atilde;o interpretativa pelo decurso do tempo e
          especificidade do caso concreto. Portanto, n&atilde;o h&aacute;
          qualquer responsabilidade entre os valores produzidos pela Plataforma
          e eventuais diverg&ecirc;ncias com o caso concreto.</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >1.7. A Empresa poder&aacute;, a seu exclusivo crit&eacute;rio,
          utilizar-se de todos os meios dispon&iacute;veis, nos limites legais e
          regulat&oacute;rios, para confirmar sua identidade e
          informa&ccedil;&otilde;es cadastrais. Caso necess&aacute;rio, a
          Empresa poder&aacute; solicitar dados adicionais e documentos que
          entenda pertinentes &agrave; confirma&ccedil;&atilde;o e
          an&aacute;lise dos dados informados.</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2 c9">2. Declara&ccedil;&otilde;es e Garantias</span>
      </p>
      <p class="c3">
        <span class="c5 c2"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >2.1. Ao utilizar os Servi&ccedil;os, o Usu&aacute;rio declara
          expressamente que tem plena capacidade, conforme definido pela
          legisla&ccedil;&atilde;o, para praticar atos da vida civil, incluindo
          a concord&acirc;ncia com estes Termos e utiliza&ccedil;&atilde;o dos
          Servi&ccedil;os. N&atilde;o &eacute; permitida a
          utiliza&ccedil;&atilde;o dos Servi&ccedil;os por menores de 18
          (dezoito) anos (&ldquo;Menores&rdquo;).</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >2.2. A utiliza&ccedil;&atilde;o dos Servi&ccedil;os &eacute; pessoal
          e exclusiva. O Usu&aacute;rio n&atilde;o pode permitir que terceiros
          utilizem sua conta na Plataforma, tampouco ced&ecirc;-la ou
          transferi-la a qualquer terceiro. Os dados de acesso &agrave;
          Plataforma, incluindo senha, s&atilde;o pessoais e
          intransfer&iacute;veis. O Usu&aacute;rio se responsabiliza de forma
          integral pela seguran&ccedil;a e confidencialidade de seus dados de
          acesso &agrave; Plataforma, eximindo a Planeje Bem de qualquer
          responsabilidade decorrente de acesso indevido.</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >2.3. Ao utilizar os Servi&ccedil;os, o Usu&aacute;rio concorda em
          cumprir com todas as leis brasileiras, bem como aquelas
          aplic&aacute;veis ao local em que est&aacute; fisicamente presente
          durante a utiliza&ccedil;&atilde;o dos Servi&ccedil;os que sejam
          eventualmente aplic&aacute;veis &agrave; Planeje Bem. O Usu&aacute;rio
          s&oacute; pode acessar os Servi&ccedil;os por meio da
          Plataforma.</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >2.4. &Eacute; responsabilidade do Usu&aacute;rio verificar e garantir
          a utiliza&ccedil;&atilde;o da Plataforma atrav&eacute;s do software
          correto para o seu dispositivo. A Empresa n&atilde;o se responsabiliza
          pela incompatibilidade do aparelho do Usu&aacute;rio com a Plataforma.
          A Empresa reserva o direito de realizar o desligamento do
          Usu&aacute;rio ou descontinuar os Servi&ccedil;os caso esteja usando
          os Servi&ccedil;os em um dispositivo incompat&iacute;vel,
          desatualizado ou n&atilde;o autorizado.</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >2.5. Ao aceitar os presentes termos e utilizar os Servi&ccedil;os, o
          Usu&aacute;rio declara que:</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >(i) s&oacute; far&aacute; uso dos Servi&ccedil;os para fins
          l&iacute;citos e previstos nestes Termos;</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >(ii) n&atilde;o utilizar&aacute; a Plataforma para enviar ou
          armazenar qualquer material ilegal ou para fins ilegais;</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >(iii) n&atilde;o incluir&aacute; senhas de qualquer tipo na
          plataforma;</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >(iv) est&aacute; ciente de que n&atilde;o poder&aacute; utilizar
          qualquer material ou conte&uacute;do que incorporem v&iacute;rus ou
          outros elementos nocivos &agrave; Plataforma;</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >(v) n&atilde;o ir&aacute; prejudicar o bom funcionamento dos
          Servi&ccedil;os de qualquer forma;</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >(vi) n&atilde;o ir&aacute; copiar ou distribuir qualquer
          conte&uacute;do sem a autoriza&ccedil;&atilde;o por escrito da
          Empresa;</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >(vii) utilizar&aacute; os Servi&ccedil;os exclusivamente para fins
          pessoal, pr&oacute;prio e intransfer&iacute;vel, sendo vedada a
          revenda a terceiros;</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >(viii) ser&aacute; unicamente respons&aacute;vel por manter de forma
          segura e confidencial a senha e qualquer outra
          identifica&ccedil;&atilde;o ou c&oacute;digo que lhe permita o acesso
          aos Servi&ccedil;os; e</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >(iv) n&atilde;o se manifestar&aacute; publicamente na Plataforma ou
          outro ve&iacute;culo de forma desrespeitosa, preconceituosa ou por
          meio de linguagem impr&oacute;pria.</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2 c9"
          >3. Propriedade Intelectual, Concess&atilde;o de Licen&ccedil;as e
          Restri&ccedil;&otilde;es</span
        >
      </p>
      <p class="c3">
        <span class="c5 c2"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >3.1. O Usu&aacute;rio tem ci&ecirc;ncia e concorda que todos os
          conte&uacute;dos e materiais apresentados pela Empresa, seja por meio
          da Plataforma ou n&atilde;o, tais como textos,
          ilustra&ccedil;&otilde;es, imagens, v&iacute;deos, fotografias, bases
          de dados, aplicativos, arquivos, redes e quaisquer outras
          cria&ccedil;&otilde;es autorais e intelectuais
          (&ldquo;Conte&uacute;do&rdquo;), s&atilde;o de propriedade exclusiva
          da Empresa ou de terceiros que autorizaram o uso por parte da
          Empresa.</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >3.2. &Eacute; expressamente proibido copiar, reproduzir, postar,
          publicar, republicar, realizar download, upload, modificar, traduzir,
          apresentar, distribuir, transmitir, realizar qualquer trabalho
          derivado ou de qualquer outra forma utilizar o Conte&uacute;do de
          forma ilegal e/ou n&atilde;o autorizada, nos termos das leis e
          tratados internacionais aplic&aacute;veis. O desrespeito a essas
          regras sujeitar&aacute; os infratores &agrave;s san&ccedil;&otilde;es
          civis e criminais correspondentes, sem preju&iacute;zo de
          indeniza&ccedil;&atilde;o por quaisquer perdas e danos &agrave;
          Planeje Bem.</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >3.3. As disposi&ccedil;&otilde;es presentes nestes Termos n&atilde;o
          configuram um contrato de venda e n&atilde;o fornecem quaisquer
          direitos de propriedade relacionados &agrave; Plataforma e aos
          Servi&ccedil;os, assegurando a manuten&ccedil;&atilde;o de quaisquer
          direitos de propriedade intelectual detidos pela Empresa. O nome da
          Empresa, o logotipo da Empresa, e os nomes de produtos associados aos
          Servi&ccedil;os s&atilde;o marcas comerciais da Empresa ou de
          terceiros, sendo vedada sua utiliza&ccedil;&atilde;o n&atilde;o
          autorizada para quaisquer fins.</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >3.4. &Eacute; concedida ao Usu&aacute;rio uma licen&ccedil;a
          n&atilde;o exclusiva e intransfer&iacute;vel de direito de uso da
          Plataforma, limitando-se a fins pessoais (n&atilde;o comerciais).
          Todos os direitos n&atilde;o expressamente concedidos ao
          Usu&aacute;rio s&atilde;o reservados &agrave; Empresa.</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2">3.5. &Eacute; expressamente vedado:</span>
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >(i) licenciar, sublicenciar, vender, revender, transferir, ceder,
          distribuir, explorar comercialmente ou disponibilizar a terceiros os
          Servi&ccedil;os de qualquer forma;</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >(ii) modificar ou realizar servi&ccedil;os derivados com base nos
          Servi&ccedil;os aqui previstos; e</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >(iii) criar &ldquo;links&rdquo; virtuais para o Servi&ccedil;o ou
          &ldquo;frame&rdquo; ou &ldquo;espelho&rdquo; da Plataforma em qualquer
          outro servidor ou dispositivo.</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >3.6. Em caso de qualquer suspeita e/ou constata&ccedil;&atilde;o de
          ilicitude, a Empresa se reserva o direito de suspender ou cancelar o
          acesso do Usu&aacute;rio &agrave; Plataforma e aos Servi&ccedil;os,
          bem como tomar as medidas legais cab&iacute;veis, incluindo, mas
          n&atilde;o se limitando a a&ccedil;&otilde;es civis e criminais de
          repara&ccedil;&atilde;o de danos. Mesmo ap&oacute;s a suspens&atilde;o
          ou o cancelamento do direito do Usu&aacute;rio de utilizar os
          Servi&ccedil;os, estes Termos continuar&atilde;o v&aacute;lidos,
          aplic&aacute;veis e vinculantes ao Usu&aacute;rio, conforme
          aplic&aacute;vel, at&eacute; que todas as obriga&ccedil;&otilde;es
          presentes nestes Termos e demais pol&iacute;ticas aplic&aacute;veis
          sejam adimplidas.</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2 c9">4. Tratamento de Dados Pessoais</span>
      </p>
      <p class="c3">
        <span class="c5 c2"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >4.1. O Usu&aacute;rio autoriza expressamente o tratamento de seus
          dados pessoais, fornecidos e compartilhados no cadastro e na
          utiliza&ccedil;&atilde;o da Plataforma, com a finalidade de prestar os
          Servi&ccedil;os adequadamente, cumprir obriga&ccedil;&otilde;es legais
          e contratuais, apresentar ofertas e propagandas compat&iacute;veis com
          o perfil do Usu&aacute;rio e customizar a oferta de produtos e
          Servi&ccedil;os, sempre nos termos da legisla&ccedil;&atilde;o e
          regulamenta&ccedil;&atilde;o vigentes. Tais dados pessoais incluem
          nome, documentos pessoais, e-mail, endere&ccedil;o e h&aacute;bitos de
          utiliza&ccedil;&atilde;o e aquisi&ccedil;&atilde;o de produtos.</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >4.2. A Planeje Bem se reserva o direito de compartilhar, reservados
          os direitos do Usu&aacute;rio, informa&ccedil;&otilde;es obtidas por
          meio de cookies e dados n&atilde;o qualificados como pessoais, nos
          termos da legisla&ccedil;&atilde;o e regulamenta&ccedil;&atilde;o
          aplic&aacute;veis, com terceiros parceiros para os fins representados,
          mas n&atilde;o limitados, de:</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >(i) presta&ccedil;&atilde;o dos Servi&ccedil;os e outros associados
          &agrave; utiliza&ccedil;&atilde;o da Plataforma;</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >(ii) prote&ccedil;&atilde;o dos interesses da Empresa, em conflitos
          judiciais e extrajudiciais;</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >(iii) transa&ccedil;&otilde;es ou altera&ccedil;&otilde;es
          societ&aacute;rias da Empresa;</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >(iv) desenvolvimento de produtos e servi&ccedil;os;</span
        >
      </p>
      <p class="c4">
        <span class="c2"
          >(v) oferta de produtos e servi&ccedil;os voltados para os interesses
          do Usu&aacute;rio; e</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >(vi) gera&ccedil;&atilde;o de dados estat&iacute;sticos acerca do uso
          dos Servi&ccedil;os.</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >4.3. Obriga&ccedil;&otilde;es, direitos e informa&ccedil;&otilde;es
          adicionais referentes ao tratamento de dados pessoais est&atilde;o
          presentes na se&ccedil;&atilde;o &ldquo;Pol&iacute;tica de
          Privacidade&rdquo; destes Termos.</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >4.4. A Planeje Bem assegura a observ&acirc;ncia e o cumprimento dos
          direitos do Usu&aacute;rio em rela&ccedil;&atilde;o aos seus dados
          pessoais, conforme legisla&ccedil;&atilde;o e
          regulamenta&ccedil;&atilde;o aplic&aacute;vel. Nos termos da Lei Geral
          de Prote&ccedil;&atilde;o de Dados (&ldquo;LGPD&rdquo;), o
          Usu&aacute;rio tem o direito de acessar, corrigir, portar, eliminar
          seus dados, al&eacute;m de confirmar que tratamos de seus dados. Em
          determinadas circunst&acirc;ncias, o Usu&aacute;rio tamb&eacute;m tem
          o direito de se opor e restringir o tratamento de seus dados
          pessoais.</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2 c9">5. Pol&iacute;tica de Privacidade</span>
      </p>
      <p class="c3">
        <span class="c5 c2"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >5.1. Esta Se&ccedil;&atilde;o informa sobre a pol&iacute;tica da
          Empresa relativas &agrave; coleta, uso, divulga&ccedil;&atilde;o e
          armazenamento de dados pessoais dos Usu&aacute;rios da Plataforma, bem
          como sobre as op&ccedil;&otilde;es que os Usu&aacute;rios possuem em
          rela&ccedil;&atilde;o a estes dados.</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >5.2. A Empresa utiliza os dados dos Usu&aacute;rios para fornecer e
          melhorar a Plataforma e, ao us&aacute;-la, o Usu&aacute;rio concorda
          com a coleta e uso de suas informa&ccedil;&otilde;es nos termos desta
          Pol&iacute;tica de Privacidade.</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >5.3. Para os fins desta Se&ccedil;&atilde;o, os seguintes termos
          pr&eacute;-definidos ser&atilde;o utilizados:</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >(i) &ldquo;Dados Pessoais&rdquo; significa dados de um
          indiv&iacute;duo em plena capacidade e que sejam capazes de
          identific&aacute;-lo;</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >(ii) &ldquo;Dados de Uso&rdquo; s&atilde;o dados coletados
          automaticamente, gerados pelo uso da Plataforma ou da pr&oacute;pria
          infraestrutura da Plataforma (por exemplo, a dura&ccedil;&atilde;o de
          uma visita &agrave; Plataforma);</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >(iii) &ldquo;Cookies&rdquo; s&atilde;o pequenos arquivos armazenados
          no dispositivo do Usu&aacute;rio (computador ou dispositivo
          m&oacute;vel);</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c2"
          >(iv) &ldquo;Controlador de Dados&rdquo; significa a pessoa
          f&iacute;sica ou jur&iacute;dica que (isoladamente ou em conjunto com
          outras pessoas) determina os fins para os quais, e a maneira pela
          qual, qualquer informa&ccedil;&atilde;o pessoal &eacute; ou deve ser
          processada. Para os fins desta Se&ccedil;&atilde;o, a Empresa &eacute;
          um Controlador de Dados dos Dados Pessoais dos Usu&aacute;rios;
          e</span
        >
      </p>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c4">
        <span class="c0"
          >(v) &ldquo;Processador de Dados&rdquo; ou &ldquo;Provedor de
          Servi&ccedil;o&rdquo; significa qualquer pessoa f&iacute;sica ou
          jur&iacute;dica que processe os dados em nome do Controlador de Dados.
          Neste sentido, a Empresa poder&aacute; usar os servi&ccedil;os de
          v&aacute;rios Provedores de Servi&ccedil;os para processar os dados
          dos Usu&aacute;rios com mais efici&ecirc;ncia.5.4. Coleta e Uso de
          Informa&ccedil;&otilde;es. Ao usar a Plataforma, a Empresa
          poder&aacute; solicitar que o Usu&aacute;rio nos forne&ccedil;a alguns
          Dados Pessoais, que podem ser usados para contat&aacute;-lo ou
          identific&aacute;-lo. Dentre as informa&ccedil;&otilde;es pessoalmente
          identific&aacute;veis, podem estar inclu&iacute;das, a t&iacute;tulo
          de exemplo n&atilde;o exaustivo:</span
        >
      </p>
      <h5 class="c1" id="h.y31wi3hstqj5">
        <span class="c0">(i) endere&ccedil;o de e-mail;</span>
      </h5>
      <h5 class="c1" id="h.y31wi3hstqj5-1">
        <span class="c0">(ii) nome e sobrenome;</span>
      </h5>
      <h5 class="c1" id="h.y31wi3hstqj5-2">
        <span class="c0">(iii) n&uacute;mero de telefone;</span>
      </h5>
      <h5 class="c1" id="h.y31wi3hstqj5-3">
        <span class="c0"
          >(iv) endere&ccedil;o, estado, prov&iacute;ncia, c&oacute;digo postal,
          cidade; e</span
        >
      </h5>
      <h5 class="c1" id="h.y31wi3hstqj5-4">
        <span class="c0">(v) Cookies e Dados de Uso.</span>
      </h5>
      <h5 class="c1" id="h.v9v505pc7wrd">
        <span class="c0"
          >5.4.1. A Empresa poder&aacute; usar, ainda, os Dados Pessoais do
          Usu&aacute;rio para contat&aacute;-lo com boletins informativos,
          materiais de marketing ou promocionais e outras
          informa&ccedil;&otilde;es que possam ser de seu interesse. O
          Usu&aacute;rio pode optar por n&atilde;o receber nenhuma ou todas
          essas comunica&ccedil;&otilde;es, seguindo o link de cancelamento de
          inscri&ccedil;&atilde;o ou as instru&ccedil;&otilde;es fornecidas em
          qualquer e-mail enviado pela Empresa.</span
        >
      </h5>
      <h5 class="c1" id="h.jwsvh5i0m67s">
        <span class="c0"
          >5.5. Dados de Uso. A Empresa poder&aacute;, tamb&eacute;m, coletar
          Dados de Uso, que podem incluir informa&ccedil;&otilde;es como o
          endere&ccedil;o IP do computador do Usu&aacute;rio, tipo de navegador,
          vers&atilde;o do navegador, as p&aacute;ginas da Plataforma que o
          Usu&aacute;rio visita, a hora e data de sua visita, o tempo gasto
          nestas p&aacute;ginas e outros dados de diagn&oacute;stico.</span
        >
      </h5>
      <h5 class="c1" id="h.ukvzgzdxdzeg">
        <span class="c0"
          >5.6. Rastreamento e Dados de Cookies. A Empresa utiliza Cookies e
          tecnologias de rastreamento semelhantes para rastrear a atividade em
          nossa Plataforma e retermos certas informa&ccedil;&otilde;es.</span
        >
      </h5>
      <h5 class="c1" id="h.xkbpblo2oj5">
        <span class="c0"
          >5.6.1. Cookies s&atilde;o arquivos com uma pequena quantidade de
          dados que podem incluir um identificador exclusivo an&ocirc;nimo. Os
          Cookies s&atilde;o enviados para o seu navegador a partir de um site e
          armazenados no seu dispositivo. Outras tecnologias de rastreamento
          tamb&eacute;m s&atilde;o usadas, como beacons, tags e scripts para
          coletar e rastrear informa&ccedil;&otilde;es, bem como para melhorar e
          analisar nossa Plataforma.</span
        >
      </h5>
      <h5 class="c1" id="h.8ak3s06986h4">
        <span class="c0"
          >5.6.2. O Usu&aacute;rio poder&aacute; instruir seu navegador para
          recusar todos os Cookies ou para indicar quando um Cookie est&aacute;
          sendo enviado. No entanto, se o Usu&aacute;rio n&atilde;o aceitar
          Cookies, talvez n&atilde;o consiga usar algumas partes da
          Plataforma.</span
        >
      </h5>
      <h5 class="c1" id="h.upgcp6m0i1ic">
        <span class="c0"
          >5.6.3. Exemplos de Cookies utilizados pela Plataforma:</span
        >
      </h5>
      <h5 class="c1" id="h.upgcp6m0i1ic-5">
        <span class="c0"
          >(i) Cookies de sess&atilde;o, para operar os Servi&ccedil;os;</span
        >
      </h5>
      <h5 class="c1" id="h.upgcp6m0i1ic-6">
        <span class="c0"
          >(ii) Cookies preferenciais, para lembrar as prefer&ecirc;ncias e
          v&aacute;rias configura&ccedil;&otilde;es dos Usu&aacute;rios; e</span
        >
      </h5>
      <h5 class="c1" id="h.upgcp6m0i1ic-7">
        <span class="c0"
          >(iii) Cookies de seguran&ccedil;a, para fins de
          seguran&ccedil;a.</span
        >
      </h5>
      <h5 class="c1" id="h.bmm9apl22pzr">
        <span class="c0"
          >5.7. Uso de Dados. A Empresa usa os dados coletados para diversos
          fins, dos quais pode-se destacar, a t&iacute;tulo
          exemplificativo:</span
        >
      </h5>
      <h5 class="c1" id="h.bmm9apl22pzr-8">
        <span class="c0">(i) para disponibilizar e manter a Plataforma;</span>
      </h5>
      <h5 class="c1" id="h.bmm9apl22pzr-9">
        <span class="c0"
          >(ii) para notificar o Usu&aacute;rio sobre altera&ccedil;&otilde;es
          na Plataforma;</span
        >
      </h5>
      <h5 class="c1" id="h.bmm9apl22pzr-10">
        <span class="c0"
          >(iii) para permitir que o Usu&aacute;rio participe de recursos
          interativos da Plataforma;</span
        >
      </h5>
      <h5 class="c1" id="h.bmm9apl22pzr-11">
        <span class="c0">(iv) para fornecer suporte ao Usu&aacute;rio;</span>
      </h5>
      <h5 class="c1" id="h.bmm9apl22pzr-12">
        <span class="c0"
          >(v) para reunir an&aacute;lises ou informa&ccedil;&otilde;es valiosas
          para melhorar a Plataforma;</span
        >
      </h5>
      <h5 class="c1" id="h.bmm9apl22pzr-13">
        <span class="c0">(vi) para monitorar o uso da Plataforma;</span>
      </h5>
      <h5 class="c1" id="h.bmm9apl22pzr-14">
        <span class="c0"
          >(vii) para detectar, prevenir e resolver problemas t&eacute;cnicos;
          e</span
        >
      </h5>
      <h5 class="c1" id="h.bmm9apl22pzr-15">
        <span class="c0"
          >(viii) para fornecer ao Usu&aacute;rio not&iacute;cias, ofertas
          especiais e informa&ccedil;&otilde;es gerais sobre outros bens,
          servi&ccedil;os e eventos ofertados na Plataforma que sejam
          semelhantes &agrave;queles que o respectivo Usu&aacute;rio j&aacute;
          tenha comprado ou pesquisado, a menos que tenha optado por n&atilde;o
          receber tais informa&ccedil;&otilde;es.</span
        >
      </h5>
      <h5 class="c1" id="h.rdruyoq9uqw5">
        <span class="c0"
          >5.8. Reten&ccedil;&atilde;o de Dados. A Empresa poder&aacute; reter
          os Dados Pessoais dos Usu&aacute;rios apenas pelo tempo que for
          necess&aacute;rio para os prop&oacute;sitos estabelecidos nesta
          Se&ccedil;&atilde;o, por no m&aacute;ximo 3 anos. A Empresa
          reter&aacute; e usar&aacute; os Dados Pessoais na medida em que seja
          necess&aacute;rio para cumprir com suas obriga&ccedil;&otilde;es
          legais, resolver disputas e fazer cumprir os contratos e
          pol&iacute;ticas legais.</span
        >
      </h5>
      <h5 class="c1" id="h.27lqeu2aq76x">
        <span class="c0"
          >5.8.1. A Empresa tamb&eacute;m reter&aacute; Dados de Uso para fins
          de an&aacute;lise interna. Os Dados de Uso s&atilde;o geralmente
          retidos por um curto per&iacute;odo de tempo, &agrave;
          exce&ccedil;&atilde;o dos casos nos quais esses dados estiverem sendo
          usados para fortalecer a seguran&ccedil;a ou para melhorar a
          funcionalidade da Plataforma, ou de quando a Empresa for legalmente
          obrigada a reter esses dados por per&iacute;odos mais longos.</span
        >
      </h5>
      <h5 class="c1" id="h.sr9gsxtdia86">
        <span class="c0"
          >5.9. Transfer&ecirc;ncia de Dados. As informa&ccedil;&otilde;es do
          Usu&aacute;rio, incluindo Dados Pessoais, podem ser transferidas para
          &ndash; e mantidas em &ndash; computadores localizados fora do seu
          estado, prov&iacute;ncia, pa&iacute;s ou de sua
          jurisdi&ccedil;&atilde;o governamental, onde as leis de
          prote&ccedil;&atilde;o de dados podem diferir.</span
        >
      </h5>
      <h5 class="c1" id="h.wkdbavmx2axi">
        <span class="c0"
          >5.9.1. O consentimento em rela&ccedil;&atilde;o aos termos destes
          Termo, seguido pelo envio das informa&ccedil;&otilde;es referidas
          nesta Se&ccedil;&atilde;o, representa a concord&acirc;ncia com a
          transfer&ecirc;ncia mencionada nesta Se&ccedil;&atilde;o.</span
        >
      </h5>
      <h5 class="c1" id="h.42pqkzr0rhv7">
        <span class="c0"
          >5.9.2. A Empresa tomar&aacute; todas as medidas razoavelmente
          cab&iacute;veis para garantir que os dados dos Usu&aacute;rios sejam
          tratados com seguran&ccedil;a e de acordo com esta
          Se&ccedil;&atilde;o, bem como para que nenhuma transfer&ecirc;ncia dos
          Dados Pessoais do Usu&aacute;rio ocorra para uma
          organiza&ccedil;&atilde;o ou pa&iacute;s, a menos que ofere&ccedil;am
          controles adequados em vigor.</span
        >
      </h5>
      <h5 class="c1" id="h.y2cxbfx7y9ox">
        <span class="c0"
          >5.10. Divulga&ccedil;&atilde;o de Dados. Se a Empresa estiver
          envolvida em uma fus&atilde;o, aquisi&ccedil;&atilde;o ou venda de
          ativos, os Dados Pessoais poder&atilde;o ser transferidos. N&atilde;o
          obstante, nesse cen&aacute;rio, a Empresa fornecer&aacute; um aviso
          antes que os Dados Pessoais sejam efetivamente transferidos e se
          tornem sujeitos a uma Pol&iacute;tica de Privacidade diferente.</span
        >
      </h5>
      <h5 class="c1" id="h.mgdanu96wpqd">
        <span class="c0"
          >5.10.1. Sob certas circunst&acirc;ncias, a Empresa pode ser
          solicitada a divulgar Dados Pessoais, caso assim seja exigido por lei
          ou por solicita&ccedil;&otilde;es v&aacute;lidas de autoridades
          p&uacute;blicas.</span
        >
      </h5>
      <h5 class="c1" id="h.fiaqktquiaaz">
        <span class="c0"
          >5.10.2. A Empresa pode, munida de boa-f&eacute;, divulgar os Dados
          Pessoais, desde que entenda assim ser necess&aacute;rio para:</span
        >
      </h5>
      <h5 class="c1" id="h.fiaqktquiaaz-16">
        <span class="c0">(i) cumprir uma obriga&ccedil;&atilde;o legal;</span>
      </h5>
      <h5 class="c1" id="h.fiaqktquiaaz-17">
        <span class="c0"
          >(ii) proteger e defender os direitos ou propriedade da Empresa;</span
        >
      </h5>
      <h5 class="c1" id="h.fiaqktquiaaz-18">
        <span class="c0"
          >(iii) evitar ou investigar poss&iacute;veis irregularidades
          relacionadas ao Servi&ccedil;o;</span
        >
      </h5>
      <h5 class="c1" id="h.fiaqktquiaaz-19">
        <span class="c0"
          >(iv) proteger a seguran&ccedil;a pessoal dos Usu&aacute;rios; e</span
        >
      </h5>
      <h5 class="c1" id="h.fiaqktquiaaz-20">
        <span class="c0"
          >(v) proteger-se contra responsabilidade legal que, eventualmente,
          venha a ser imputada.</span
        >
      </h5>
      <h5 class="c1" id="h.707h99dfac47">
        <span class="c0"
          >5.11. Seguran&ccedil;a de Dados. A seguran&ccedil;a dos dados dos
          Usu&aacute;rios &eacute; importante para a Empresa, mas o
          Usu&aacute;rio deve lembrar-se de que nenhum m&eacute;todo de
          transmiss&atilde;o pela internet ou de armazenamento eletr&ocirc;nico
          &eacute; 100% (cem por cento) seguro. Fica expresso, no entanto, que a
          Empresa se compromete a envidar seus melhores esfor&ccedil;os para
          proteger os Dados Pessoais, de modo que a Empresa usar&aacute; os
          meios comercialmente aceit&aacute;veis para tanto.</span
        >
      </h5>
      <h5 class="c1" id="h.fagvvjkp5g46">
        <span class="c0"
          >5.12 Direitos de prote&ccedil;&atilde;o de dados sob o LGPD. O
          Usu&aacute;rio tem certos direitos de prote&ccedil;&atilde;o de dados
          espec&iacute;ficos que s&atilde;o elencados no LGPD. A Empresa
          objetiva tomar todas as medidas que forem economicamente
          razo&aacute;veis para permitir que o Usu&aacute;rio corrija, altere,
          elimine ou limite o uso de seus Dados Pessoais.</span
        >
      </h5>
      <h5 class="c1" id="h.k43ox6ov854j">
        <span class="c0"
          >5.12.1. Se o Usu&aacute;rio desejar ser informado sobre quais de seus
          Dados Pessoais ser&atilde;o mantidos, ou se desejar que sejam
          removidos dos sistemas da Empresa, o respectivo Usu&aacute;rio
          dever&aacute; entrar em contato com a Empresa com
          solicita&ccedil;&atilde;o espec&iacute;fica nesse sentido.</span
        >
      </h5>
      <h5 class="c1" id="h.5355s62kgq82">
        <span class="c0"
          >5.12.2. Em determinadas circunst&acirc;ncias, os Usu&aacute;rios
          t&ecirc;m os seguintes direitos de prote&ccedil;&atilde;o de
          dados:</span
        >
      </h5>
      <h5 class="c1" id="h.5355s62kgq82-21">
        <span class="c0"
          >(i) Direito de acessar, atualizar ou excluir as
          informa&ccedil;&otilde;es. Sempre que poss&iacute;vel, o
          Usu&aacute;rio poder&aacute; acessar, atualizar ou solicitar a
          exclus&atilde;o de seus dados pessoais diretamente na
          se&ccedil;&atilde;o de configura&ccedil;&otilde;es da sua conta. Se o
          Usu&aacute;rio n&atilde;o puder realizar essas a&ccedil;&otilde;es
          sozinho, dever&aacute; entrar em contato com a Empresa;</span
        >
      </h5>
      <h5 class="c1" id="h.5355s62kgq82-22">
        <span class="c0"
          >(ii) Direito de retifica&ccedil;&atilde;o. O Usu&aacute;rio tem o
          direito de retificar suas informa&ccedil;&otilde;es, caso estas
          informa&ccedil;&otilde;es estejam imprecisas ou incompletas;</span
        >
      </h5>
      <h5 class="c1" id="h.5355s62kgq82-23">
        <span class="c0"
          >(iii) Direito de obje&ccedil;&atilde;o. O Usu&aacute;rio tem o
          direito de se opor ao processamento de seus dados pessoais;</span
        >
      </h5>
      <h5 class="c1" id="h.5355s62kgq82-24">
        <span class="c0"
          >(iv) Direito de restri&ccedil;&atilde;o. O Usu&aacute;rio tem o
          direito de solicitar que a Empresa restrinja o processamento de suas
          informa&ccedil;&otilde;es pessoais;</span
        >
      </h5>
      <h5 class="c1" id="h.5355s62kgq82-25">
        <span class="c0"
          >(v) Direito &agrave; portabilidade de dados. O Usu&aacute;rio tem o
          direito de receber uma c&oacute;pia das informa&ccedil;&otilde;es que
          a Empresa possui sobre ele em um formato estruturado, leg&iacute;vel
          por m&aacute;quina e comumente utilizado; e</span
        >
      </h5>
      <h5 class="c1" id="h.5355s62kgq82-26">
        <span class="c0"
          >(vi) Direito de retirar o consentimento. O Usu&aacute;rio
          tamb&eacute;m tem o direito de retirar seu consentimento a qualquer
          momento, nos casos em que seu consentimento era necess&aacute;rio para
          processar suas informa&ccedil;&otilde;es pessoais.</span
        >
      </h5>
      <h5 class="c1" id="h.x4f7tlinqddm">
        <span class="c0"
          >5.12.3. O Usu&aacute;rio deve se atentar para o fato de que a Empresa
          pode pedir-lhe para confirmar a sua identidade antes de responder a
          esses pedidos.</span
        >
      </h5>
      <h5 class="c1" id="h.xj8ys8z8sm30">
        <span class="c0"
          >5.12.4. O Usu&aacute;rio tem o direito de reclamar com uma autoridade
          de prote&ccedil;&atilde;o de dados sobre a coleta e uso de seus Dados
          Pessoais.</span
        >
      </h5>
      <h5 class="c1" id="h.whw50k7xg0l">
        <span class="c0"
          >5.13. Provedores de Servi&ccedil;o. A Empresa poder&aacute; contratar
          empresas terceirizadas e indiv&iacute;duos para facilitar a atividade
          da Empresa, realiz&aacute;-la em seu nome, executar servi&ccedil;os
          relacionados &agrave; Plataforma ou auxiliar a Empresa na
          an&aacute;lise de como a Plataforma &eacute; utilizada.</span
        >
      </h5>
      <h5 class="c1" id="h.ylq1lfb8pbs9">
        <span class="c0"
          >5.13.1 Esses terceiros t&ecirc;m acesso aos Dados Pessoais apenas
          para realizar as tarefas que tiverem sido designados para realizar em
          nome da Empresa, e s&atilde;o obrigados a n&atilde;o
          divulg&aacute;-los ou us&aacute;-los para qualquer outra
          finalidade.</span
        >
      </h5>
      <h5 class="c1" id="h.qpormxmcbur5">
        <span class="c0"
          >5.14. An&aacute;lise de dados. A Empresa poder&aacute; usar
          Provedores de Servi&ccedil;o terceirizados para monitorar e analisar o
          uso de nossa Plataforma, tais como Google Analytics e Mixpanel.</span
        >
      </h5>
      <h5 class="c1" id="h.spj7lmq1pkni">
        <span class="c0"
          >5.15. Links para Outros Sites. A Plataforma pode conter links para
          outros sites que n&atilde;o s&atilde;o operados pela Empresa. Se o
          Usu&aacute;rio clicar em um link de terceiros, ele ser&aacute;
          direcionado para outro site. Sendo assim, recomenda-se que o
          Usu&aacute;rio se atente &agrave; pol&iacute;tica de privacidade de
          todos os sites que visita.</span
        >
      </h5>
      <h5 class="c1" id="h.afzo3jhklygh">
        <span class="c0"
          >5.15.1. A Empresa n&atilde;o tem controle e n&atilde;o assume
          qualquer responsabilidade pelo conte&uacute;do, pol&iacute;ticas de
          privacidade ou pr&aacute;ticas de quaisquer sites ou servi&ccedil;os
          de terceiros.</span
        >
      </h5>
      <h5 class="c1" id="h.dheqqd32bdx4">
        <span class="c0"
          >5.16. Privacidade das Crian&ccedil;as. A Empresa n&atilde;o coleta
          intencionalmente informa&ccedil;&otilde;es de
          identifica&ccedil;&atilde;o pessoal de menores de idade. Pais,
          m&atilde;es ou respons&aacute;veis legais que estejam cientes de que
          um menor de idade sob sua responsabilidade forneceu dados pessoais
          &agrave; Empresa, dever&atilde;o entrar em contato com a Empresa. Se a
          Empresa ficar ciente de que foi coletado Dados Pessoais de Menores de
          Idade sem a verifica&ccedil;&atilde;o do consentimento dos
          respons&aacute;veis, a Empresa tomar&aacute; medidas para remover
          essas informa&ccedil;&otilde;es de seus servidores.</span
        >
      </h5>
      <h5 class="c1" id="h.ttaq008mjt8k">
        <span class="c5 c2">6. Isen&ccedil;&atilde;o de Garantias</span>
      </h5>
      <h5 class="c1" id="h.9t7z8vhwwzph">
        <span class="c0"
          >6.1. Sem preju&iacute;zo das demais disposi&ccedil;&otilde;es
          previstas nestes Termos, n&atilde;o &eacute; garantido pela Empresa
          que:</span
        >
      </h5>
      <h5 class="c1" id="h.duh44ljyjvwy">
        <span class="c0"
          >(i) o uso da Plataforma ser&aacute; sempre seguro, oportuno,
          ininterrupto, sem erros ou funcionar&aacute; em
          combina&ccedil;&atilde;o com qualquer outro sistema;</span
        >
      </h5>
      <h5 class="c1" id="h.duh44ljyjvwy-27">
        <span class="c0"
          >(ii) entregar&aacute; mensagens, v&iacute;deos e/ou
          correspond&ecirc;ncias a destinat&aacute;rios designados pelo
          Usu&aacute;rio, dentro dos prazos e formas designadas;</span
        >
      </h5>
      <h5 class="c1" id="h.duh44ljyjvwy-28">
        <span class="c0"
          >(iii) os Servi&ccedil;os atender&atilde;o sempre as necessidades ou
          expectativas do Usu&aacute;rio;</span
        >
      </h5>
      <h5 class="c1" id="h.duh44ljyjvwy-29">
        <span class="c0"
          >(iv) a validade jur&iacute;dica dos Servi&ccedil;os ser&aacute;
          reconhecida pelo judici&aacute;rio;</span
        >
      </h5>
      <h5 class="c1" id="h.duh44ljyjvwy-30">
        <span class="c0"
          >(v) erros ou defeitos na Plataforma, inclusive relacionados a
          informa&ccedil;&otilde;es sobre produtos e Servi&ccedil;os,
          ser&atilde;o corrigidos imediatamente; e</span
        >
      </h5>
      <h5 class="c1" id="h.duh44ljyjvwy-31">
        <span class="c0"
          >(vi) a Plataforma estar&aacute; livre de v&iacute;rus ou outros
          componentes nocivos permanentemente.</span
        >
      </h5>
      <h5 class="c1" id="h.dxhiix745r6t">
        <span class="c0"
          >6.2. A Empresa n&atilde;o possui qualquer garantia referente a
          problemas de conex&atilde;o &agrave; internet. Assim, Servi&ccedil;os
          da Empresa podem estar sujeitos &agrave;s limita&ccedil;&otilde;es,
          atrasos e outros problemas relacionados &agrave; conex&atilde;o de
          internet, servidores, redes e demais componentes relacionados a
          infraestrutura eletr&ocirc;nica e virtual. A Empresa n&atilde;o se
          responsabiliza pela impossibilidade ou dificuldade de
          utiliza&ccedil;&atilde;o dos Servi&ccedil;os, quaisquer atrasos,
          falhas ou quaisquer danos resultantes de tais problemas.</span
        >
      </h5>
      <h5 class="c1" id="h.xcnokm1zpo8i">
        <span class="c0"
          >6.3. A Empresa se compromete a envidar os melhores esfor&ccedil;os
          para disponibilizar os Servi&ccedil;os com qualidade, a partir de
          altos padr&otilde;es t&eacute;cnicos para as atividades
          desempenhadas.</span
        >
      </h5>
      <h5 class="c1" id="h.wcx7dipzwe3s">
        <span class="c5 c2"
          >7. Limita&ccedil;&otilde;es de Responsabilidade</span
        >
      </h5>
      <h5 class="c1" id="h.f7qy0u5hnsnf">
        <span class="c0"
          >7.1. O Usu&aacute;rio est&aacute; ciente e concorda que a Empresa,
          sem preju&iacute;zo das demais cl&aacute;usulas destes Termos,
          n&atilde;o se responsabiliza:</span
        >
      </h5>
      <h5 class="c1" id="h.4ocjqimf2w0u">
        <span class="c0"
          >(i) por quaisquer problemas ou funcionamento inadequado de
          dispositivos e equipamentos de terceiros, mesmo que relacionados ao
          uso regular da Plataforma;</span
        >
      </h5>
      <h5 class="c1" id="h.4ocjqimf2w0u-32">
        <span class="c0"
          >(ii) por eventos relacionados ou a&ccedil;&otilde;es e/ou
          omiss&otilde;es de terceiros, como ataques cibern&eacute;ticos, falhas
          em sistemas, no servidor ou na conex&atilde;o &agrave; internet,
          a&ccedil;&otilde;es ou omiss&otilde;es de entregadores terceirizados,
          entre outros;
        </span>
      </h5>
      <h5 class="c1" id="h.8syjslxjvji3">
        <span class="c0"
          >(iii) pela instabilidade e/ou n&atilde;o funcionamento da Plataforma,
          independentemente do motivo;</span
        >
      </h5>
      <h5 class="c1" id="h.4ocjqimf2w0u-33">
        <span class="c0"
          >(iv) por erros inconting&ecirc;ncia no sistema que alterem a
          apresenta&ccedil;&atilde;o de dados, caracter&iacute;sticas ou
          informa&ccedil;&otilde;es de produtos;</span
        >
      </h5>
      <h5 class="c1" id="h.4ocjqimf2w0u-34">
        <span class="c0"
          >(v) por falhas na entrega de mensagens, v&iacute;deos e/ou
          correspond&ecirc;ncias enviadas pelo Usu&aacute;rios a
          terceiros;</span
        >
      </h5>
      <h5 class="c1" id="h.4ocjqimf2w0u-35">
        <span class="c0"
          >(vi) por eventuais informa&ccedil;&otilde;es incompletas, imprecisas
          ou desatualizadas disponibilizadas por terceiros na Plataforma;</span
        >
      </h5>
      <h5 class="c1" id="h.4ocjqimf2w0u-36">
        <span class="c0"
          >(vii) pelo acesso de terceiros &agrave; conta do Usu&aacute;rio,
          sobretudo se decorrente de utiliza&ccedil;&atilde;o de dados de acesso
          do Usu&aacute;rio de forma inadequada e/ou em desconformidade com
          estes Termos;
        </span>
      </h5>
      <h5 class="c1" id="h.4ocjqimf2w0u-37">
        <span class="c0"
          >(viii) pelas minutas geradas pela plataforma, uma vez que n&atilde;o
          s&atilde;o arquivadas pela Planeje Bem;
        </span>
      </h5>
      <h5 class="c1" id="h.ca7lb5l5kdue">
        <span class="c0"
          >(ix) pela promo&ccedil;&atilde;o de servi&ccedil;os de consultoria
          jur&iacute;dica demandados pelo Usu&aacute;rio; e</span
        >
      </h5>
      <h5 class="c1" id="h.b66exqplxcp5">
        <span class="c0"
          >(x) pela presta&ccedil;&atilde;o de Servi&ccedil;os a Usu&aacute;rio
          em desconformidade com estes Termos ou quaisquer pol&iacute;ticas da
          Planeje Bem.</span
        >
      </h5>
      <h5 class="c1" id="h.lw684cyfkzjz">
        <span class="c0"
          >7.2. Ao utilizar os Servi&ccedil;os, o Usu&aacute;rio reconhece que
          &eacute; o &uacute;nico respons&aacute;vel pelo uso da sua conta na
          Plataforma. O Usu&aacute;rio &eacute; respons&aacute;vel por manter a
          confidencialidade de qualquer senha ou dado provido &agrave; Empresa
          para utilizar os Servi&ccedil;os. O Usu&aacute;rio &eacute; o
          &uacute;nico respons&aacute;vel por atividades realizadas em sua
          conta, incluindo compras e/ou outras atividades decorrentes de acessos
          indevidos por terceiros. Caso haja suspeitas de que uma pessoa
          n&atilde;o autorizada est&aacute; utilizando sua conta, o
          Usu&aacute;rio deve notificar a Empresa imediatamente e alterar os
          dados de acesso, se poss&iacute;vel.</span
        >
      </h5>
      <h5 class="c1" id="h.7bfwlsb3wgpz">
        <span class="c0"
          >7.3. &Eacute; reservado &agrave; Empresa o direito de remover, a seu
          exclusivo crit&eacute;rio, qualquer conte&uacute;do inserido pelo
          Usu&aacute;rio que a Empresa considere ser contr&aacute;rio ao
          disposto na legisla&ccedil;&atilde;o e/ou nestes Termos, bem como que
          tenham conte&uacute;do impr&oacute;prio, chulo, ofensivo,
          preconceituoso, rude, obsceno ou de baixo cal&atilde;o, sem que seja
          devida qualquer forma de ressarcimento ao Usu&aacute;rio.</span
        >
      </h5>
      <h5 class="c1" id="h.8fp7tlx68bsr">
        <span class="c5 c2">8. Comunica&ccedil;&atilde;o com a Empresa</span>
      </h5>
      <h5 class="c1" id="h.az59nh6yszhn">
        <span class="c2"
          >8.1. Ser&atilde;o consideradas v&aacute;lidas todas as
          comunica&ccedil;&otilde;es realizadas &agrave; Empresa quando
          direcionadas aos seguintes canais de comunica&ccedil;&atilde;o
          oficiais: (i) chat dispon&iacute;vel dentro da Plataforma, ap&oacute;s
          sua disponibiliza&ccedil;&atilde;o; (ii) e-mail
        </span>
        <span class="c13 c2">
          <a class="c6" href="mailto:contato@planejebem.app"
            >contato@planejebem.app</a
          >
        </span>
        <span class="c0">.</span>
      </h5>
      <h5 class="c1" id="h.2si5t4l6cscn">
        <span class="c0"
          >8.2. Ser&atilde;o consideradas recebidas todas as
          comunica&ccedil;&otilde;es realizadas pela Empresa ao Usu&aacute;rio
          por meio dos endere&ccedil;os cadastrados no sistema da Empresa. A
          Empresa n&atilde;o se responsabiliza pela incorre&ccedil;&atilde;o ou
          desatualiza&ccedil;&atilde;o dos dados cadastrais do Usu&aacute;rio, o
          qual tem o dever de mant&ecirc;-los sempre corretos e
          atualizados.</span
        >
      </h5>
      <h5 class="c1" id="h.nir1r8yzkxkm">
        <span class="c5 c2">9. T&eacute;rmino do Contrato</span>
      </h5>
      <h5 class="c1" id="h.dxwe5s6u8ucf">
        <span class="c0"
          >9.1. Estes Termos entram em vigor na data de in&iacute;cio da
          utiliza&ccedil;&atilde;o da Plataforma ou dos Servi&ccedil;os.
          &Eacute; reservado ao Usu&aacute;rio ou &agrave; Empresa o direito de
          rescindir a rela&ccedil;&atilde;o contratual e encerrar a
          utiliza&ccedil;&atilde;o do Servi&ccedil;o a qualquer momento, por
          qualquer motivo.</span
        >
      </h5>
      <h5 class="c1" id="h.sdfimwsv3h23">
        <span class="c0"
          >9.2. Observada a legisla&ccedil;&atilde;o aplic&aacute;vel, a Empresa
          pode proibir o uso dos Servi&ccedil;os pelo Usu&aacute;rio a qualquer
          momento, a seu exclusivo crit&eacute;rio, independentemente de
          pr&eacute;vio aviso, n&atilde;o sendo devido qualquer tipo de
          indeniza&ccedil;&atilde;o ao Usu&aacute;rio em raz&atilde;o
          disso.</span
        >
      </h5>
      <h5 class="c1" id="h.jm5onk9e79d5">
        <span class="c0"
          >9.3. A rescis&atilde;o destes Termos &eacute; realizada de forma
          concomitante &agrave; exclus&atilde;o da conta de acesso do
          Usu&aacute;rio &agrave; Plataforma.</span
        >
      </h5>
      <h5 class="c1" id="h.epn605pd1x9m">
        <span class="c5 c2">10. Disposi&ccedil;&otilde;es Finais</span>
      </h5>
      <h5 class="c1" id="h.klfqqhit6eis">
        <span class="c0"
          >10.1. N&atilde;o se pretende criar pela Empresa, em nenhuma
          hip&oacute;tese, rela&ccedil;&atilde;o de parceria, emprego, trabalho,
          join venture, franquia, sociedade ou outras que excedam uma simples
          transa&ccedil;&atilde;o comercial de compra e venda entre comprador
          (Usu&aacute;rio) e vendedor (Empresa) relacionada &agrave;
          utiliza&ccedil;&atilde;o dos Servi&ccedil;os e da Plataforma
          disponibilizada ao Usu&aacute;rio.</span
        >
      </h5>
      <h5 class="c1" id="h.vpt20mqtgom0">
        <span class="c0"
          >10.2. Estes Termos s&atilde;o regidos exclusivamente pelas leis
          brasileiras.</span
        >
      </h5>
      <h5 class="c1" id="h.svq5is2ik9iw">
        <span class="c0"
          >10.3. Qualquer cl&aacute;usula ou condi&ccedil;&atilde;o destes
          Termos que, por qualquer raz&atilde;o, seja considerada
          inv&aacute;lida, nula ou ineficaz por uma jurisdi&ccedil;&atilde;o
          competente, n&atilde;o afetar&aacute; a validade das demais
          cl&aacute;usulas presentes nos Termos, os quais permanecer&atilde;o
          plenamente v&aacute;lidos e vinculantes, com for&ccedil;a e efeito
          legal.</span
        >
      </h5>
      <h5 class="c1" id="h.c73p5g92qq0h">
        <span class="c0"
          >10.4. Caso a Empresa deixe de exigir quaisquer direitos ou
          disposi&ccedil;&otilde;es destes Termos, isso n&atilde;o
          constituir&aacute; ren&uacute;ncia, podendo exerc&ecirc;-los
          regularmente dentro dos prazos legais.</span
        >
      </h5>
      <h5 class="c1" id="h.np9su5qgbzaf">
        <span class="c0"
          >10.5. Fica eleito o Foro do Munic&iacute;pio de S&atilde;o Paulo para
          a resolu&ccedil;&atilde;o de qualquer lit&iacute;gio resultante da
          interpreta&ccedil;&atilde;o ou execu&ccedil;&atilde;o dos presentes
          Termos, renunciando as partes a qualquer outro, por mais privilegiado
          que seja.</span
        >
      </h5>
      <p class="c3">
        <span class="c0"></span>
      </p>
      <p class="c8">
        <span class="c0"></span>
      </p>
    </div>
  </div>
  <div
    class="d-flex align-items-center justify-content-between mt-5 w-100 px-6 px-lg-15 pb-5 action-bar"
  >
    <button class="bl-back back-button-terms" (click)="logout()">VOLTAR</button>
    <button class="btn btn-primary add-button" (click)="updateTermsUser()">
      <div
        *ngIf="isLoading | async"
        class="spinner spinner-secondary mr-10"
      ></div>
      ACEITAR E CONTINUAR
    </button>
  </div>
</ng-template>
